import { useFeatureValue } from '@growthbook/growthbook-react'

import type { Recommendations } from '../../../../generated/frontend'
import { RecommendedArticles } from '../RecommendedArticles'

const RecommendedForYouComponent = ({
  recommendations,
  whichId,
  modelId,
}: {
  recommendations: Recommendations
  whichId: string
  modelId: string
}) => {
  const variant = useFeatureValue('recommended-for-you', 'control')

  const matchingModel = recommendations.models.find((model) => model.model === variant)

  if (variant === 'control' || !matchingModel || !matchingModel.content) {
    return null
  }

  return (
    <RecommendedArticles articles={matchingModel.content} whichId={whichId} modelId={modelId} />
  )
}

export const RecommendedForYouContainer = ({
  recommendations,
  whichId,
  modelId,
}: {
  recommendations: Recommendations
  whichId: string
  modelId: string
}) => {
  const allModelsHaveContent = recommendations.models.every((model) => model.content !== null)

  if (allModelsHaveContent) {
    return (
      <RecommendedForYouComponent
        recommendations={recommendations}
        whichId={whichId}
        modelId={modelId}
      />
    )
  } else {
    return null
  }
}
